import React from "react";
import BlackBgWhiteTick from "../../svg/svgs/black-bg-white-tick.svg";
function PricingTrial() {
  return (
    <div>
      <div className="pricing-trial-block trial max-container">
        <strong>
          Trial&nbsp;
          <span style={{ fontSize: "24px" }}>$0</span>
        </strong>
        <div className="list-btn-grid">
          <ul>
            <li>
              <img src={BlackBgWhiteTick} alt="Tick Icon" />
              0$ for 1 month
            </li>
            <li>
              <img src={BlackBgWhiteTick} alt="Tick Icon" />4 videos limit
            </li>
            <li>
              <img src={BlackBgWhiteTick} alt="Tick Icon" />5 GB Bandwidth
            </li>

            <li>
              <img src={BlackBgWhiteTick} alt="Tick Icon" />
              All features except Fairplay DRM for trial
            </li>
            <li>
              <img src={BlackBgWhiteTick} alt="Tick Icon" />
              Valid for 1 month, No credit card required
            </li>
          </ul>
          <div>
            <a href="/dashboard/register/" className="trial-btn">
              Sign Up
            </a>
          </div>
        </div>
        <div className="inquiries">
          If you have business inquiries or other questions, please send an
          email to &nbsp;
          <a className="mailto" href="mailto:support@vdocipher.com">
            support@vdocipher.com
          </a>
        </div>
      </div>
    </div>
  );
}

export default PricingTrial;
