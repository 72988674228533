import React, { useState, useEffect } from "react";
import PricingTrial from "../../components/pricing-components/pricing-trial";
import PricingDesktop from "./pricing-desktop";
import PricingMobile from "./pricing-mobile";
import PlanPopup from "./plan-popup";
import { getLoginId } from "../../utils/user/info-utils";

import { simpleRequest } from "../../utils/api";
function PricingTable({ plans, tickFeatures }) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const handleScreenWidth = () => setScreenWidth(window.innerWidth);
  const [showPopup, setShowPopup] = useState(false);
  // Independent state for Pro and Premium plans
  const [isProYearly, setIsProYearly] = useState(true);
  const [isPremiumYearly, setIsPremiumYearly] = useState(true);
  const enterprisePlanObj = {
    codename: "enterprise",
  };

  useEffect(() => {
    window.addEventListener("resize", handleScreenWidth);
    return window.addEventListener("resize", handleScreenWidth);
  }, []);

  // Toggle handler for Pro plan
  const handleProToggle = () => {
    setIsProYearly((prevState) => !prevState);
  };

  // Toggle handler for Premium plan
  const handlePremiumToggle = () => {
    setIsPremiumYearly((prevState) => !prevState);
  };

  const isLoggedIn = !!getLoginId();

  const handleBuyNowClick = async (eachPlanObj) => {
    if (isLoggedIn) {
      // Check for Pro or Premium plan based on the amount and state
      const isPro = eachPlanObj.codename === "Pro";
      const isPremium = eachPlanObj.codename === "Premium";

      if (
        ((!isProYearly && isPro) || (!isPremiumYearly && isPremium)) &&
        !localStorage.getItem("submittedData")
      ) {
        setShowPopup(true);

        // Determine the plan type based on the selected toggle
        const planType = isPro ? "Pro" : "Premium";

        // Log the assigned planType for debugging
        const emailId = localStorage.getItem("username");
        const dynamicUserData = {
          email: emailId,
          query: `${planType} - ${
            (isPro && isProYearly) || (isPremium && isPremiumYearly)
              ? "Yearly Plan"
              : "Quarterly Plan"
          }`,
        };

        try {
          const url = `/enquiry-form`;
          const response = await simpleRequest({
            url: url,
            method: "POST",
            data: dynamicUserData,
          });

          localStorage.setItem(
            "submittedData",
            JSON.stringify(dynamicUserData)
          );
        } catch (error) {}
      } else {
        window.location.href = "/dashboard/account";
      }
    }
  };

  // Close popup function
  const closePopup = () => {
    setShowPopup(false);
  };

  const mobileDesktopProps = {
    plans,
    tickFeatures,
    enterprisePlanObj,
    handleBuyNowClick,
    isProYearly,
    setIsProYearly,
    isPremiumYearly,
    setIsPremiumYearly,
    handleProToggle,
    handlePremiumToggle,
    showPopup,
    setShowPopup,
    closePopup,
  };

  return (
    <div>
      <div className="pricing-links">
        <h2 className="pricing-main">
          For every $1 spent on VdoCipher, customers see $30 in revenue growth
          by preventing piracy & adding paid users
        </h2>
        <div className="pricing-button-group">
          <a
            href="https://www.vdocipher.com/blog/vdocipher-price-estimate/"
            className="pricing-grouped"
            target="_blank"
          >
            Pricing Calculator
          </a>
          <a
            href="https://calendly.com/vdocipher/pricing-discussion-demo/?month=2024-10"
            className="pricing-grouped"
            target="_blank"
          >
            Schedule Demo
          </a>
        </div>
      </div>
      <div className="full-screen-border"></div>

      {screenWidth < 992 && (
        <PricingMobile
          {...mobileDesktopProps}
          handleBuyNowClick={handleBuyNowClick}
          handleProToggle={handleProToggle}
          handlePremiumToggle={handlePremiumToggle}
          setShowPopup={setShowPopup}
          showPopup={showPopup}
          isProYearly={isProYearly}
          setIsProYearly={setIsProYearly}
          isPremiumYearly={isPremiumYearly}
          setIsPremiumYearly={setIsPremiumYearly}
          closePopup={closePopup}
        />
      )}

      {screenWidth >= 992 && (
        <PricingDesktop
          {...mobileDesktopProps}
          handleBuyNowClick={handleBuyNowClick}
          handleProToggle={handleProToggle}
          handlePremiumToggle={handlePremiumToggle}
          setShowPopup={setShowPopup}
          showPopup={showPopup}
          closePopup={closePopup}
        />
      )}

      <PricingTrial />
      {showPopup && <PlanPopup closePopup={closePopup} />}
    </div>
  );
}
export default PricingTable;
