import React from "react";
import GetSVG from "../../svg";

const PlanPopup = ({ closePopup }) => {
  return (
    <div className="popup">
      <div className="popup-content">
        <a className="popup-close-button" onClick={closePopup}>
          <GetSVG svgName="close" />
        </a>
        <img
          src="https://www.vdocipher.com/blog/wp-content/uploads/2023/11/check.png"
          alt="Check Icon"
          style={{ width: "54px", height: "54px" }}
        />
        <h2 className="popup-heading">
          You have selected the quarterly plan. Thank you.
        </h2>
        <h2 className="popup-heading">
          Our support team will contact you shortly.
        </h2>
      </div>
    </div>
  );
};

export default PlanPopup;
