import React, { useState } from "react";
import ProToggleButton from "./pricing-pro-toggle";
import PremiumToggleButton from "./pricing-premium-toggle";
import { getLoginId } from "../../utils/user/info-utils";
import PlanPopup from "./plan-popup";

const PricingMobileColumn = ({
  eachPlanObj,
  tickFeatures,
  isEnterprise,
  handleBuyNowClick,
  isProYearly,
  setIsProYearly,
  isPremiumYearly,
  setIsPremiumYearly,
  handleProToggle,
  handlePremiumToggle,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const bandwidthValidityRow = () => {
    return (
      <>
        <strong className="highlight-feature">
          {`${eachPlanObj.bandwidth} and ${eachPlanObj.validity}`}
        </strong>
        <div>
          {eachPlanObj.approxHoursNumeric ? (
            `Estimated hours~${eachPlanObj.approxHoursNumeric} Streaming based on 800kbps video`
          ) : (
            <>&#8212;</>
          )}
        </div>
      </>
    );
  };

  const storageRow = () => {
    return (
      <>
        <strong className="highlight-feature ">
          {`${eachPlanObj.max_storage} Storage`}
        </strong>
        <p>
          *Accounted storage can be 2 to 4 times of uploads, for new files for
          each device.
        </p>
      </>
    );
  };

  const tickFeaturesRow = ({ eachTickFeatures }) => {
    if (!isEnterprise) {
      return eachTickFeatures.plans.includes(
        eachPlanObj.codename.toLowerCase()
      ) ? (
        <div
          className="pricing-column-row"
          key={`${eachPlanObj.codename}-${eachTickFeatures.label}`}
        >
          {eachTickFeatures.label}
        </div>
      ) : null;
    }
    return (
      <div
        className="pricing-column-row"
        key={`enterprise-${eachTickFeatures.label}`}
      >
        {eachTickFeatures.label}
      </div>
    );
  };
  const isLoggedIn = !!getLoginId();
  const getButtonText = () => {
    if (isLoggedIn) {
      return "Buy Now";
    } else {
      return "Free Trial";
    }
  };
  const buyButtonClickHandler = (plan) => {
    if (!isLoggedIn) {
      window.location.href = "/dashboard/register";
      return;
    }
    if (["Pro", "Premium"].includes(plan.codename)) {
      return handleBuyNowClick(plan);
    } else {
      return (window.location.href = "/dashboard/account");
    }
  };
  return (
    <div className="pricing-column" id={eachPlanObj.codename}>
      {/*  Heading */}
      <div className={`heading pricing-column-row`}>{eachPlanObj.codename}</div>

      {/* Non-Discounted Amount*/}
      {!isEnterprise && eachPlanObj.non_discount_amount_usd && (
        <div className="non-discount-amt sub-value pricing-column-row border-y-none">
          {`$ ${eachPlanObj.non_discount_amount_usd} /year`}
        </div>
      )}

      {/* Discount */}
      <div className="discount border-y-none pricing-column-row">
        {!["pro", "premium"].includes(eachPlanObj.codename.toLowerCase()) && (
          <span className="amt">
            {!isEnterprise ? `$${eachPlanObj.amount_usd}` : "Custom"}
          </span>
        )}

        {!["pro", "premium"].includes(eachPlanObj.codename.toLowerCase()) && (
          <span className="per-year">
            {!isEnterprise ? (
              <>/year</>
            ) : (
              <>
                <br />
                <span className="per-year">for larger scale</span>
              </>
            )}
          </span>
        )}

        {eachPlanObj.codename.toLowerCase() === "pro" && (
          <ProToggleButton
            amountUsd={eachPlanObj.amount_usd}
            setIsProYearly={setIsProYearly}
            isProYearly={isProYearly}
            handleProToggle={handleProToggle}
          />
        )}

        {eachPlanObj.codename.toLowerCase() === "premium" && (
          <PremiumToggleButton
            amountUsd={eachPlanObj.amount_usd}
            setIsPremiumYearly={setIsPremiumYearly}
            isPremiumYearly={isPremiumYearly}
            handlePremiumToggle={handlePremiumToggle}
          />
        )}
      </div>

      {/* Free Trial button */}
      <div className=" border-y-none pricing-column-row">
        <span className="border-y-none" key={`buyBtn_${eachPlanObj.id}`}>
          <button
            className="plan-buy-button"
            onClick={() => buyButtonClickHandler(eachPlanObj)}
          >
            {getButtonText()}
          </button>
        </span>

        {/* Render ToggleButton for Pro Plan */}
      </div>

      {/* Bandwidth,  Validity  and Estimated Hours*/}
      {!isEnterprise ? (
        <div className={` pricing-column-row border-y-none `}>
          {bandwidthValidityRow()}
        </div>
      ) : null}

      {/* Storage */}
      {!isEnterprise ? (
        <div className={`pricing-column-row `}>{storageRow()}</div>
      ) : null}

      {/* Extra Bandwidth and extra Storage */}
      {!isEnterprise ? (
        <div className="pricing-column-row border-top">
          {eachPlanObj.overageBandwidth && eachPlanObj.overageStorage ? (
            `Extra Bandwidth/Storage Price: Bandwidth-$${eachPlanObj.overageBandwidth}/GB & Storage-$${eachPlanObj.overageStorage}/GB.Card is never auto-charged. Bandwidth unit
      price reduces for bulk purchase`
          ) : (
            <>&#8212;</>
          )}
        </div>
      ) : null}

      {/* Enterprise highlights */}
      {isEnterprise ? (
        <div className={` pricing-column-row border-bottom`}>
          <ul>
            <li>Account Manager</li>
            <li>Whatsapp & Call Support</li>
            <li>Scale to 10 Million Viewers</li>
            <li>Private APIs & User Based Analytics</li>
            <li>SLA, 99.94% uptime</li>
            <li>Better Bandwidth price</li>
            <li>Multi-Tenant Features for Course/Website Builders</li>
          </ul>
          <a href="https://www.vdocipher.com/enterprise/" target="_blank">
            More Details here
          </a>
        </div>
      ) : null}

      {/* Tick Features */}
      {tickFeatures.map((eachTickFeatures) => {
        return tickFeaturesRow({ eachTickFeatures });
      })}

      {/* Free Trial button */}
      <div className=" border-y-none pricing-column-row">
        {!isEnterprise ? (
          <span className="border-y-none" key={`buyBtn_${eachPlanObj.id}`}>
            <button
              className="plan-buy-button"
              onClick={() => buyButtonClickHandler(eachPlanObj)}
            >
              {getButtonText()}
            </button>
          </span>
        ) : (
          <a href="/site/contact" className="plan-buy-button">
            Contact Us
          </a>
        )}
      </div>

      {showPopup && <PlanPopup closePopup="closePopup" />}
    </div>
  );
};

export default PricingMobileColumn;
