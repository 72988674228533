import React from "react";

const ProToggleButton = ({
  amountUsd,
  setIsProYearly,
  isProYearly,
  handleProToggle,
}) => {
  return (
    <div className="toggle-button">
      <div className="plan-details">
        {/* Show details based on the toggle state */}
        {isProYearly ? (
          <div className="discount yearly-plan">
            <span className="amt">${amountUsd}</span>
            <span>/year</span>
          </div>
        ) : (
          <div className="discount quarterly-plan">
            <span className="amt">$500</span>
            <span>/quarter</span>
          </div>
        )}
      </div>

      <div className="toggle-container">
        <span
          className={`yearly-text ${isProYearly ? "active selected" : ""}`}
          onClick={() => setIsProYearly(true)} // Select Yearly
        >
          Yearly
        </span>
        <label className="switch">
          <input
            type="checkbox"
            onChange={handleProToggle}
            checked={!isProYearly}
          />
          <span
            className={`slider round ${isProYearly ? "orange-bg" : "blue-bg"}`}
          ></span>
        </label>
        <span
          className={`quarterly-text ${!isProYearly ? "active blue-text" : ""}`}
          onClick={() => setIsProYearly(false)} // Select Quarterly
        >
          Quarterly
        </span>
      </div>
    </div>
  );
};

export default ProToggleButton;
