import React from "react";

const PricingLoading = () => {
  const loadingStyles = {
    height: "50vh",
    backgroundColor: "lightgray",
    border: 0,
    margin: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "none",
  };
  return [1, 1, 1, 1, 1, 1, 1].map((e, index) => (
    <div className={"col s12 m3 l3"} style={loadingStyles} key={index}>
      ...
    </div>
  ));
};

export default PricingLoading;
