import React from "react";
const PricingError = () => {
  const styles = {
    height: "50vh",
    backgroundColor: "lightgray",
    border: 0,
    margin: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "none",
    textAlign: "center",
  };
  return (
    <div className={"col s12"} style={styles}>
      Sorry, we are having some difficulties loading pricing plans. Please try
      again later.
    </div>
  );
};

export default PricingError;
