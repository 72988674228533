import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import React from "react";
import more from "../../svg/svgs/more.svg";

const PricingTooltip = ({ featureContent }) => {
  return (
    <Tippy
      content={<div dangerouslySetInnerHTML={{ __html: featureContent }}></div>}
      className="pricing-tooltip"
      hideOnClick={false}
      interactive={true}
      allowHTML={true}
    >
      <img
        src={more}
        alt="Feature Label Description"
        className="description-img"
      />
    </Tippy>
  );
};

export default PricingTooltip;
