import React from "react";
import PricingMobileColumn from "./pricing-mobile-column";
import PricingLite from "./pricing-lite";

const PricingMobile = ({
  plans,
  tickFeatures,
  buyButton,
  enterprisePlanObj,
  handleBuyNowClick,
  handleProToggle,
  handlePremiumToggle,
  setShowPopup,
  showPopup,
  isProYearly,
  setIsProYearly,
  isPremiumYearly,
  setIsPremiumYearly,
}) => {
  return (
    <div className="pricing-columns-container">
      <PricingLite buyButton={buyButton} />
      {plans.map((eachPlanObj, index) => {
        return (
          <PricingMobileColumn
            eachPlanObj={eachPlanObj}
            tickFeatures={tickFeatures}
            buyButton={buyButton}
            enterprisePlan={false}
            key={eachPlanObj.id}
            handleBuyNowClick={handleBuyNowClick}
            handleProToggle={handleProToggle}
            handlePremiumToggle={handlePremiumToggle}
            setShowPopup={setShowPopup}
            showPopup={showPopup}
            isProYearly={isProYearly}
            setIsProYearly={setIsProYearly}
            isPremiumYearly={isPremiumYearly}
            setIsPremiumYearly={setIsPremiumYearly}
          />
        );
      })}
      <PricingMobileColumn
        eachPlanObj={enterprisePlanObj}
        tickFeatures={tickFeatures}
        buyButton={buyButton}
        isEnterprise={true}
        handleBuyNowClick={handleBuyNowClick}
        handleProToggle={handleProToggle}
        handlePremiumToggle={handlePremiumToggle}
        setShowPopup={setShowPopup}
        showPopup={showPopup}
        isProYearly={isProYearly}
        setIsProYearly={setIsProYearly}
        isPremiumYearly={isPremiumYearly}
        setIsPremiumYearly={setIsPremiumYearly}
      />
    </div>
  );
};

export default PricingMobile;
