import React from "react";

const PremiumToggleButton = ({
  amountUsd,
  isPremiumYearly,
  setIsPremiumYearly,
  handlePremiumToggle,
}) => {
  return (
    <div className="toggle-button">
      <div className="plan-details">
        {/* Show details based on the toggle state */}
        {isPremiumYearly ? (
          <div className="discount yearly-plan">
            <span className="amt">${amountUsd}</span>
            <span>/year</span>
          </div>
        ) : (
          <div className="discount quarterly-plan">
            <span className="amt">$1600</span>
            <span>/quarter</span>
          </div>
        )}
      </div>

      <div className="toggle-container">
        <span
          className={`yearly-text ${isPremiumYearly ? "active selected" : ""}`}
          onClick={() => setIsPremiumYearly(true)} // Select Yearly
        >
          Yearly
        </span>
        <label className="switch">
          <input
            type="checkbox"
            onChange={handlePremiumToggle}
            checked={!isPremiumYearly}
          />
          <span
            className={`slider round ${
              isPremiumYearly ? "orange-bg" : "blue-bg"
            }`}
          ></span>
        </label>
        <span
          className={`quarterly-text ${
            !isPremiumYearly ? "active blue-text" : ""
          }`}
          onClick={() => setIsPremiumYearly(false)} // Select Quarterly
        >
          Quarterly
        </span>
      </div>
    </div>
  );
};

export default PremiumToggleButton;
